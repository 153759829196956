import projectApiService from '@/network/project';
import geometryValidationApiService from '@/network/geometryValidation';
import { EventBus } from '@/network/eventbus';
import viewer from './viewer';
import simulationAsset from './simulation-asset';
import auth from './auth';
import permissions from './permissions';
import { AUTHOR_INSIGHTS } from '@/constants/permissions';
import _ from 'lodash';

function initialState() {
  return {
    allProjects: [],
    projectShares: [],
    projectAdministrators: [],
    projectPage: 1,
    projectSearchPage: 1,
    uploadedScenario: null,
    cameraPositions: [],
    selectedProject: null,
    loading: true,
    selectedStudyId: null,
    selectedSimulationLabels: [],
    currentInsightIndex: 0,
    camerasWithInsights: [],
    projectAssetUploadData: null,
    projectAssetUploadDataForProjectAssets: null,
    badStations: [],
    insightPinTypeTemplates: [],
    insightPinType: null,
    scenarioCreationInformation: { formValues: null, projectInformation: null, response: null, mlParameters: null, cfdParameters: null },
    scenario: null,
    submittedFormValues: null,
    createScenarioSuccessModalVisible: false,
    scenarioConfirmationModalIsVisible: false,
    deleteScenarioModalIsVisible: false,
    inboundScenario: null,
    projectAdminCreated: false,
    projectAdminDeleted: false,
    createScenarioModalIsVisible: false,
    projectAdminDeletedError: false,
    createProjectAdminError: false,
    projectShareCreated: false,
    createProjectShareError: false,
    projectShareDeleted: false,
    deleteProjectShareError: false,
    userInviteCreated: false,
    createUserInviteError: false,
    projectShareUpdated: false,
    updateProjectShareError: false,
    projectUpdated: false,
    projectUpdatedError: false,
    isPreviewModeOpen: false,
    viewerMode: 'Viewer',  //Viewer, Insights, Settings, Reports, or Images
    dataPanelOpen: true,
    selectedScenarioFromProperties: null,
    currentReport: null,
    currentImage: null,
    error: null,
    selectedSimulationType: null,
    createScenarioStep: 0, // 0 = Upload and categorize geometry, 1 = Review geometry integrity, 2 = Select simulation parameters
    createScenarioBtnClicked: false, //Used to check if the final button has been clicked for scenario creation
    backBtnClicked: false, //Used to check if the back button has been clicked for scenario creation
    fileUploadErrors: false, //Used to check if there are errors in the file uploader for scenario creation,
    simulationParametersErrors: false, //Used to check if there are errors in the simulation parameters for scenario creation,
    formUploading: false, //Used to check if a file is currently uploading,
    invalidFilename: false, //used to check if a filename is invalid (starts with a number)
    invalidSeasons: false,
    invalidTimes: false,
    invalidWinds: false,
    invalidSingleDate: false,
    invalidSingleDayHours: false, 
    invalidMinutesInterval: false,
    invalidReturnPeriods: {},
    projectSurrounds: null,
    jobTypes: [],
    criteria: [],
    eulaMarketingOptIn: false,
    scenarioSubmissionInProgress: false,
    automatedGeometryValidationResults: [],
    automatedGeometryValidationError: false,
    filesToFix: [],
    scenarioSaveInProgress: false,
    createInsightMode: false,
    sceneViewCompositionMode: false,
    annotationsCompositionMode: false,
    newAnnotationAdded: false,
    editInsightMode: false,
    creatingInsightPDF: false,
    showCreatingInsightPDFLoadingScreen: false,
    insightTypeSelected: '',
    savingSceneViewInsight: false,
    settingsTabActive: 'navigator',
    selectedThemeIndex: 0,
    savedCameraPositionId: null,
    newInsightTitle: '',
    newInsightDesc: '',
    newInsightStatus: 'Draft',
    newAnnotationTitle: '',
    newAnnotationDesc: '',
    annotationTypeToCreate: null,
    drawingMode: false,
    createInsightStep: 0, // 0 = Insight type selection, 1 = Resouce(Image,Report) selection/upload
    currentInsightResource: null,
    productFruits: false,
    layersPanelOpen: true,
    currentInsightImage: null,
    pinTypes: null
  };
}

const state = initialState();

const getters = {
  createInsightMode: state => state.createInsightMode,
  pinTypes: state => state.pinTypes,
  sceneViewCompositionMode: state => state.sceneViewCompositionMode,
  annotationsCompositionMode: state => state.annotationsCompositionMode,
  newAnnotationAdded: state => state.newAnnotationAdded,
  editInsightMode: state => state.editInsightMode,
  creatingInsightPDF: state => state.creatingInsightPDF,
  showCreatingInsightPDFLoadingScreen: state => state.showCreatingInsightPDFLoadingScreen,
  eulaMarketingOptIn: state => state.eulaMarketingOptIn,
  layersPanelOpen: state => state.layersPanelOpen,
  currentInsightImage: state => state.currentInsightImage,
  allProjects(state) {
    return state.allProjects;
  },
  projectShares(state) {
    return state.projectShares;
  },
  projectAdministrators(state) {
    return state.projectAdministrators;
  },
  badStations(state) {
    return state.badStations;
  },
  insightPinTypeTemplates(state) {
    return state.insightPinTypeTemplates;
  },
  insightPinType(state) {
    return state.insightPinType;
  },
  selectedProject(state) {
    return state.selectedProject;
  },
  cameraPositions(state) {
    return state.cameraPositions;
  },
  userInviteCreated(state) {
    return state.userInviteCreated;
  },
  createUserInviteError(state) {
    return state.createUserInviteError;
  },
  projectAdminCreated(state) {
    return state.projectAdminCreated;
  },
  projectAdminDeleted(state) {
    return state.projectAdminDeleted;
  },
  projectShareCreated(state) {
    return state.projectShareCreated;
  },
  projectAdminDeletedError(state) {
    return state.projectAdminDeletedError;
  },
  createProjectAdminError(state) {
    return state.createProjectAdminError;
  },
  projectShareDeleted(state) {
    return state.projectShareDeleted;
  },
  deleteProjectShareError(state) {
    return state.deleteProjectShareError;
  },
  projectShareUpdated(state) {
    return state.projectShareUpdated;
  },
  updateProjectShareError(state) {
    return state.updateProjectShareError;
  },
  projectUpdated(state) {
    return state.projectUpdated;
  },
  projectUpdatedError(state) {
    return state.projectUpdatedError;
  },
  createProjectShareError(state) {
    return state.createProjectShareError;
  },
  uploadedScenario(state) {
    return state.uploadedScenario;
  },
  name(state) {
    if (!state.selectedProject) {
      return null;
    }
    return state.selectedProject.name;
  },
  studies(state) {
    if (!state.selectedProject) {
      return null;
    }
    return state.selectedProject.studies;
  },
  loading(state) {
    return state.loading;
  },
  createScenarioModalIsVisible(state) {
    return state.createScenarioModalIsVisible;
  },
  scenarioConfirmationModalIsVisible(state) {
    return state.scenarioConfirmationModalIsVisible;
  },
  deleteScenarioModalIsVisible(state) {
    return state.deleteScenarioModalIsVisible;
  },
  projectIsLoading(state) {
    return state.selectedProject == null;
  },
  selectedStudy(state) {
    if (!getters.studies(state) || !state.selectedStudyId) {
      return null;
    }
    return getters.studies(state).find(study => study.id === state.selectedStudyId);
  },
  selectedSimulationLabels(state) {
    return state.selectedSimulationLabels;
  },
  showReviewedByRWDIButton(state) {
    return getters.selectedStudy(state)?.IsRWDIProject;
  },
  currentInsightIndex(state) {
    return state.currentInsightIndex;
  },
  currentInsights(state) {
    if (!getters.selectedStudy(state)) {
      return [];
    }

    if (auth.getters.loggedInUser(auth.state)?.is_superuser || 
      ((state.selectedProject.company_id == auth.getters.loggedInUser(auth.state)?.company_id ||
      permissions.getters.userCompany(permissions.state)?.is_rwdi) && permissions.getters.allowedPermissions(permissions.state)[AUTHOR_INSIGHTS])) {
      return [...getters.selectedStudy(state)?.insights]
        .sort((first, second) => first.sort_order > second.sort_order ? 1 : -1);
    } else { 
      return [...getters.selectedStudy(state)?.insights]
        .sort((first, second) => first.sort_order > second.sort_order ? 1 : -1)
        .filter(insgt => insgt.insight_status=='Published');
    }
  },
  currentInsight(state) {
    return getters.currentInsights(state)[getters.currentInsightIndex(state)];
  },
  firstSceneView(state) {
    if (!getters.currentInsight(state)) {
      return null;
    }
    return getters.currentInsight(state).sceneview_set[0];
  },
  camerasWithInsights(state) {
    let camerasWithInsights = [];
    if (!getters.currentInsights(state)) {
      return [];
    }
    for (const insight of getters.currentInsights(state)) {
      let cameraPosition = insight?.sceneview_set[0]?.camera_position?.id;
      if (cameraPosition) {
        camerasWithInsights.push(cameraPosition);
      }
    }
    return camerasWithInsights;
  },
  assetUploadUrlAvailable(state) {
    return state.projectAssetUploadData != null;
  },
  assetUploadUrl(state) {
    return `${state.projectAssetUploadData?.container_url}${state.projectAssetUploadData?.uuid}?${state.projectAssetUploadData?.sas_token}`;
  },
  projectAssetUploadUrl(state) {
    return `${state.projectAssetUploadDataForProjectAssets?.container_url}${state.projectAssetUploadDataForProjectAssets}?${state.projectAssetUploadDataForProjectAssets?.sas_token}`;
  },
  uploadUuid(state) {
    return state.projectAssetUploadData?.uuid;
  },
  containerUrl(state) {
    return state.projectAssetUploadData?.container_url;
  },
  projectAssetsContainerUrl(state) {
    return state.projectAssetUploadDataForProjectAssets?.container_url;
  },
  sasToken(state) {
    return state.projectAssetUploadData?.sas_token;
  },
  projectAssetsSasToken(state) {
    return state.projectAssetUploadDataForProjectAssets?.sas_token;
  },
  submittedFormValues(state) {
    return state.submittedFormValues;
  },
  createdScenarioInformation(state) {
    return state.scenarioCreationInformation.projectInformation;
  },
  createdScenarioFormValues(state) {
    return state.scenarioCreationInformation.formValues;
  },
  createdScenarioId(state) {
    return state.scenarioCreationInformation.response?.id;
  },
  inboundScenario(state) {
    return state.inboundScenario;
  },
  isPreviewModeOpen(state) {
    return state.isPreviewModeOpen;
  },
  scenario(state) {
    return state.scenario;
  },
  createScenarioSuccessModalVisible(state) {
    return state.createScenarioSuccessModalVisible;
  },
  viewerMode(state) {
    return state.viewerMode;
  },
  selectedScenarioFromProperties(state) {
    return state.selectedScenarioFromProperties;
  },
  currentReport(state) {
    return state.currentReport;
  },
  currentImage(state) {
    return state.currentImage;
  },
  settingsTabActive(state) {
    return state.settingsTabActive;
  },
  insightTypeSelected(state) {
    return state.insightTypeSelected;
  },
  currentInsightResource(state) {
    return state.currentInsightResource;
  },
  newInsightTitle(state) {
    return state.newInsightTitle;
  },
  productFruits(state) {
    return state.productFruits;
  },
  newAnnotationTitle(state) {
    return state.newAnnotationTitle;
  },
  newAnnotationDesc(state) {
    return state.newAnnotationDesc;
  },
  newInsightDesc(state) {
    return state.newInsightDesc;
  },
  newInsightStatus(state) {
    return state.newInsightStatus;
  },
  savingSceneViewInsight(state) {
    return state.savingSceneViewInsight;
  },
  selectedThemeIndex(state) {
    return state.selectedThemeIndex;
  },
  savedCameraPositionId(state) {
    return state.savedCameraPositionId;
  },
  error(state) {
    return state.error;
  },
  selectedSimulationType(state) {
    return state.selectedSimulationType;
  },
  createScenarioStep(state) {
    return state.createScenarioStep;
  },
  filesToFix(state) {
    return state.filesToFix;
  },
  createScenarioBtnClicked(state) {
    return state.createScenarioBtnClicked;
  },
  backBtnClicked(state) {
    return state.backBtnClicked;
  },
  fileUploadErrors(state) {
    return state.fileUploadErrors;
  },
  simulationParametersErrors(state) {
    return state.simulationParametersErrors;
  },
  formUploading(state) {
    return state.formUploading;
  },
  invalidFilename(state) {
    return state.invalidFilename;
  },
  invalidSeasons(state) {
    return state.invalidSeasons;
  },
  invalidTimes(state) {
    return state.invalidTimes;
  },
  invalidWinds(state) {
    return state.invalidWinds;
  },
  invalidReturnPeriods(state) {
    return state.invalidReturnPeriods;
  },
  invalidSingleDate(state) {
    return state.invalidSingleDate;
  },
  invalidSingleDayHours(state) {
    return state.invalidSingleDayHours;
  }, 
  invalidMinutesInterval(state) {
    return state.invalidMinutesInterval;
  },
  projectSurrounds(state) {
    return state.projectSurrounds;
  },
  jobTypes(state) {
    return state.jobTypes;
  },
  criteria(state) {
    return state.criteria;
  },
  scenarioSubmissionInProgress(state) {
    return state.scenarioSubmissionInProgress;
  },
  automatedGeometryValidationResults(state) {
    return state.automatedGeometryValidationResults;
  },
  automatedGeometryValidationError(state) {
    return state.automatedGeometryValidationError;
  },
  scenarioSaveInProgress(state) {
    return state.scenarioSaveInProgress;
  },
  annotationTypeToCreate(state) {
    return state.annotationTypeToCreate;
  },
  drawingMode(state) {
    return state.drawingMode;
  },
  dataPanelOpen(state) {
    return state.dataPanelOpen;
  },
  createInsightStep(state) {
    return state.createInsightStep;
  }
};

const actions = {
  getAll({ commit }) {
    return projectApiService.getAll().then(response => {
      commit('setProjects', response);
    }).catch(error => {
      commit('setError', error);
    });
  },
  getProjectShares({ commit }, projectId) {
    if (projectId) {  //projectId could be undefined if the user navigated away from the calling page and chagned the route before this metods triggered
      return projectApiService.getProjectShares(projectId).then(response => {
        commit('setProjectShares', response);
      }).catch(error => {
        commit('setError', error);
      });
    }
  },
  getProjectAdministrators({ commit }, projectId) {
    if (projectId) { //projectId could be undefined if the user navigated away from the calling page and chagned the route before this metods triggered
      return projectApiService.getProjectAdministrators(projectId).then(response => {
        commit('setProjectAdministrators', response);
      }).catch(error => {
        commit('setError', error);
      });
    }
  },
  getPage({ commit, state }) {
    return projectApiService.getPage(state.projectPage).then(response => {
      commit('concatProjects', response);
      commit('incrementProjectPage');
    }).catch(error => {
      if(error.response.data.detail === 'Invalid page.') {
        throw new Error('Invalid page.');
      } else {
        commit('setError', error);
      }
    });
  },
  getSearchPage({ commit, state }, searchTerm) {
    return projectApiService.getSearchPage(state.projectSearchPage, searchTerm).then(response => {
      commit('concatProjects', response);
      commit('incrementprojectSearchPage');
    }).catch(error => {
      if(error.response.data.detail === 'Invalid page.') {
        throw new Error('Invalid page.');
      } else {
        commit('setError', error);
      }
    });
  },
  async getProject({ commit }, id) {
    if (id) {  //id could be undefined if the user navigated away from the calling page and chagned the route before this metods triggered
      try {
        commit('setProject', null);
        let response = await projectApiService.getProject(id);
        commit('setProject', response);
      } catch(error) {
        commit('setError', error);
      }
    }
  },
  getProjectsByName({ commit }, name) {
    commit('setProjects', null);
    return projectApiService.getProjectsByName(name).then(response => {
      commit('setProjects', response);
    }).catch(error => {
      commit('setError', error);
    });
  },
  getCameraPositions({ commit }, { projectId, studyId }) {
    return projectApiService.getCameraPositions(projectId, studyId).then(response => {
      commit('setCameraPositions', response);
    }).catch(error => {
      commit('setError', error);
    });
  },
  async addCameraPosition({ commit, dispatch }, { projectId, studyId, cameraPosition }) {
    try {
      const response = await projectApiService.addCameraPosition(projectId, studyId, cameraPosition);
      await dispatch('getCameraPositions', { projectId, studyId });
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async addInsight({ commit }, { projectId, studyId, insightDetails }) {
    try {
      const response = await projectApiService.addInsight(projectId, studyId, insightDetails);
      let savedInsight = {
        studyId: studyId,
        insight: response
      };
      commit('addInsightToStudy', savedInsight);
      // return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async deleteInsight( { commit }, { projectId, studyId, insightId }) {
    try {
      await projectApiService.deleteInsight(projectId, studyId, insightId);
      commit('deleteInsight',  insightId );
      EventBus.$emit('TOAST', {
        variant: 'success',
        content: 'Insight deleted successfully.'
      });
    } catch (ex) {
      EventBus.$emit('TOAST', {
        variant: 'danger',
        content: 'Failed to delete insight.  Please try again.'
      });
    }
  },
  async addSceneView({ commit }, { projectId, studyId, insightId, insightDetails }) {
    try {
      const response = await projectApiService.addSceneView(projectId, studyId, insightId, insightDetails);
      let savedSceneview = {
        studyId: studyId,
        insightId: insightId,
        sceneView: response
      };
      commit('addSceneViewToInsight', savedSceneview);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async deleteSceneView({ commit }, { projectId, studyId, insightId, sceneViewId }) {
    try {
      await projectApiService.deleteSceneView(projectId, studyId, insightId, sceneViewId);
      let insight = {
        studyId: studyId,
        insightId: insightId
      };
      commit('clearSceneViewFromInsight', insight);
    } catch(e) {
      console.error(e);
    }
  },
  async addInsightPin({ commit }, { projectId, studyId, insightId, insightPinDetails, uuid }) {
    try {
      const response = await projectApiService.addInsightPin(projectId, studyId, insightId, insightPinDetails);
      let savedPin = {
        studyId: studyId,
        insightId: insightId,
        pin: response
      };
      savedPin.pin.item = uuid;
      commit('addPinToInsight', savedPin);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async addInsightPinType({ commit }, { projectId, studyId, insightId, insightPinTypeDetails }) {
    try {
      const response = await projectApiService.addInsightPinType(projectId, studyId, insightId, insightPinTypeDetails);
      let savedPinType = {
        studyId: studyId,
        insightId: insightId,
        pinType: response
      };
      commit('addPinTypeToInsight', savedPinType);
      commit('setInsightPinType', response);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async deleteInsightPin( {commit }, {projectId, studyId, insightId, annotationId} ) {
    try {
      await projectApiService.deleteInsightPin(projectId, studyId, insightId, annotationId);
      commit('deletePinFromInsight', {projectId, studyId, insightId, annotationId} );
      return true;
    } catch (error) {
      commit('setError', error);
      return false;
    }
  },
  clearPinsWithPinTypeId({ commit }, {projectId, studyId, insightId, annotationTypeId}) {
    commit('clearPinsWithPinTypeId', {projectId, studyId, insightId, annotationTypeId});
  },
  async deleteInsightPinType( {commit }, {projectId, studyId, insightId, annotationTypeId} ) {
    try {
      await projectApiService.deleteInsightPinType(projectId, studyId, insightId, annotationTypeId);
      commit('deletePinTypeFromInsight', {projectId, studyId, insightId, annotationTypeId} );
      return true;
    } catch (error) {
      commit('setError', error);
      return false;
    }
  },
  async updateInsightPin({ commit }, { projectId, studyId, insightId, insightPinId, updatedInsightPinDetails }) {
    try {
      const response = await projectApiService.updateInsightPin(projectId, studyId, insightId, insightPinId, updatedInsightPinDetails);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async updateInsightPinType({ commit }, { projectId, studyId, insightId, insightPinTypeId, updatedInsightPinTypeDetails }) {
    try {
      const response = await projectApiService.updateInsightPinType(projectId, studyId, insightId, insightPinTypeId, updatedInsightPinTypeDetails);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async updateCameraPosition({ commit, dispatch }, { projectId, studyId, id, updatedCameraPosition }) {
    try {
      const response = await projectApiService.updateCameraPosition(projectId, studyId, id, updatedCameraPosition);
      await dispatch('getCameraPositions', { projectId, studyId });
      return response;
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async deleteCameraPosition({ commit, dispatch }, { projectId, studyId, cameraPositionId }) {
    try {
      const response = await projectApiService.deleteCameraPosition(projectId, studyId, cameraPositionId);
      await dispatch('getCameraPositions', { projectId, studyId });
      return response;
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async updateProject({ commit}, { id, updatedParameters }) {
    let success = true;
    try {
      await projectApiService.updateProject(id, updatedParameters);
    } catch (error) {
      state.projectUpdatedError = true;
      setTimeout(() => {
        state.projectUpdatedError = false;
      }, 3000);
      success = false;
      commit('setError', error);
    }
    if (success) {
      state.projectUpdated = true;
      setTimeout(() => {
        state.projectUpdated = false;
      }, 3000);
    }
  },
  async createProjectAdministrator({ commit, dispatch }, {projectId, newAdmin}) {
    let success = true;
    try {
      await projectApiService.createProjectAdministrator(projectId, newAdmin);
      await dispatch('getProjectAdministrators', projectId);
    } catch (error) {
      state.createProjectAdminError = true;
      setTimeout(() => {
        state.createProjectAdminError = false;
      }, 3000);
      success = false;
      commit('setError', error);
    }
    if (success) {
      state.projectAdminCreated = true;
      setTimeout(() => {
        state.projectAdminCreated = false;
      }, 3000);
    }
  },
  async deleteProjectAdministrator({ commit, dispatch }, {projectId, projectAdminId}) {
    let success = true;
    try {
      await projectApiService.deleteProjectAdministrator(projectId, projectAdminId);
      await dispatch('getProjectAdministrators', projectId);
    } catch (error) {
      state.projectAdminDeletedError = true;
      setTimeout(() => {
        state.projectAdminDeletedError = false;
      }, 3000);
      success = false;
      commit('setError', error);
    }
    if (success) {
      state.projectAdminDeleted = true;
      setTimeout(() => {
        state.projectAdminDeleted = false;
      }, 3000);
    }
  },
  async createProjectShare({ commit, dispatch }, {projectId, values}) {
    let success = true;
    try {
      await projectApiService.createProjectShare(projectId, values);
      await dispatch('getProjectShares', projectId);
    } catch (error) {
      state.createProjectShareError = true;
      setTimeout(() => {
        state.createProjectShareError = false;
      }, 3000);
      success = false;
      commit('setError', error);
    }
    if (success) {
      state.projectShareCreated = true;
      setTimeout(() => {
        state.projectShareCreated = false;
      }, 3000);
    }
  },
  async updateProjectShare({ commit, dispatch }, {projectId, projectShareId, newValues}) {
    let success = true;
    try {
      await projectApiService.updateProjectShare(projectId, projectShareId, newValues);
      await dispatch('getProjectShares', projectId);
    } catch (error) {
      state.updateProjectShareError = true;
      setTimeout(() => {
        state.updateProjectShareError = false;
      }, 3000);
      success = false;
      commit('setError', error);
    }
    if (success) {
      state.projectShareUpdated = true;
      setTimeout(() => {
        state.projectShareUpdated = false;
      }, 3000);
    }
  },
  async createUserInvite({ commit}, {projectId, values}) {
    let success = true;
    try {
      await projectApiService.createUserInvite(projectId, values);
    } catch (error) {
      state.createUserInviteError = true;
      setTimeout(() => {
        state.createUserInviteError = false;
      }, 3000);
      success = false;
      commit('setError', error);
    }
    if (success) {
      state.userInviteCreated = true;
      setTimeout(() => {
        state.userInviteCreated = false;
      }, 3000);
    }
  },
  async deleteProjectShare({ commit, dispatch }, {projectId, projectShareId}) {
    let success = true;
    try {
      await projectApiService.deleteProjectShare(projectId, projectShareId);
      await dispatch('getProjectShares', projectId);
    } catch (error) {
      state.deleteProjectShareError = true;
      setTimeout(() => {
        state.deleteProjectShareError = false;
      }, 3000);
      success = false;
      commit('setError', error);
    }
    if (success) {
      state.projectShareDeleted = true;
      setTimeout(() => {
        state.projectShareDeleted = false;
      }, 3000);
    }
  },
  selectStudyId({ commit }, id) {
    commit('setSelectedStudyId', id);
  },
  selectSimulationLabels({ commit }, simulationLabels) {
    commit('setSelectedSimulationLabels', simulationLabels);
  },
  switchInsight({ commit }, insight) {
    commit('setSwitchInsight', insight);
  },
  setInboundScenario({ commit }, data) {
    commit('setInboundScenario', data);
  },
  setScenarioModalVisibility({ commit }, data) {
    commit('setScenarioModalVisibility', data);
  },
  setScenarioConfirmationModalVisibility({ commit }, data) {
    commit('setScenarioConfirmationModalVisibility', data);
  },
  setCreateScenarioBtnClicked({ commit }, data) {
    commit('setCreateScenarioBtnClicked', data);
  },
  setBackBtnClicked({ commit }, data) {
    commit('setBackBtnClicked', data);
  },
  setformErrors({ commit }, data) {
    commit('setformErrors', data);
  },
  setFormUploading({ commit }, data) {
    commit('setFormUploading', data);
  },
  setInvalidFilename({ commit }, data) {
    commit('setInvalidFilename', data);
  },
  setInvalidSeasons({ commit }, data) {
    commit('setInvalidSeasons', data);
  },
  setInvalidTimes({ commit }, data) {
    commit('setInvalidTimes', data);
  },
  setInvalidWinds({ commit }, data) {
    commit('setInvalidWinds', data);
  },
  setInvalidSingleDate({ commit }, data) {
    commit('setInvalidSingleDate', data);
  },
  setInvalidSingleDayHours({ commit }, data) {
    commit('setInvalidSingleDayHours', data);
  }, 
  setInvalidMinutesInterval({ commit }, data) {
    commit('setInvalidMinutesInterval', data);
  },
  setInvalidReturnPeriods({ commit }, data) {
    commit('setInvalidReturnPeriods', data);
  },
  setDeleteScenarioModalVisibility({ commit }, data) {
    commit('setDeleteScenarioModalVisibility', data);
  },
  openPreviewMode({ commit }, data) {
    commit('setPreviewMode', data);
  },
  async createProject({ commit }, { project, metData }) {
    return projectApiService.createProject(project).then(async (response) => {
      try {
        if (project.generateSurrounds) projectApiService.generateSurroundsOSM(response.id);  //don't await, let the modal close and the user move on while surrounds are being generated
        if (response.fetch_met) {
          let metResp = await projectApiService.fetchMetData(response.id, metData);
          response.met_sources = metResp;  //add the metsources to the project response so that the project object can be used to create a scenario (which checks the met) without being reloaded
          commit('concatProjects', [response]);
        } else {
          commit('concatProjects', [response]);
        }
        return response.id;
      } catch (e) {
        commit('setError', 'An error occurred getting meteorological data for your project. Please try again by clicking on the \'Details\' button of the project card and resubmitting the request');
      }

    }).catch(error => {
      commit('setError', error);
    });
  },
  async fetchMetData({ commit }, { projectId, metData }) {
    let resp = await projectApiService.fetchMetData(projectId, metData);
    commit('setFetchMetForProject', projectId);
    return resp;
  },
  getBadStations({ commit }) {
    return projectApiService.getBadStations()
      .then(response => commit('setBadStations', response))
      .catch(error => commit('setError', error));
  },
  getInsightPinTypeTemplates({ commit }) {
    return projectApiService.getInsightPinTypeTemplates()
      .then(response => commit('setInsightPinTypeTemplates', response))
      .catch(error => commit('setError', error));
  },
  getProjectAssetUploadUrl({ commit }, projectId) {
    commit('setProjectAssetUploadData', null);
    return projectApiService.getBlobUrl(projectId)
      .then(response => commit('setProjectAssetUploadData', response))
      .catch(error => commit('setError', error));
  },
  getProjectAssetUploadUrlForProjectAssets({ commit }, projectId) {
    commit('setProjectAssetUploadDataForProjectAssets', null);
    return projectApiService.getBlobUrlForProjectAssets(projectId)
      .then(response => commit('setProjectAssetUploadDataForProjectAssets', response))
      .catch(error => commit('setError', error));
  },
  setSubmittedFormValues({ commit }, data) {
    commit('setSubmittedFormValues', data);
  },
  async createScenario({ commit }, { projectId, scenario, formValues, mlParameterValues, cfdParameterValues }) {
    commit('setScenarioCreationInformation', { scenario: null, projectInformation: null, mlParameters: null });
    try {
      const response = await projectApiService.createScenario(projectId, scenario);
      //await dispatch('getAll');
      const simulationId = response.simulations[0].id;
      const studyId = response.study_id;
      const configurationId = response.id;
      const projectInformation = {
        projectId,
        studyId,
        simulationId,
        configurationId
      };
      commit('setSelectedSimulationType', formValues['simulation_type']);
      commit('setScenarioCreationInformation', { scenario: formValues, projectInformation, response, mlParameters: mlParameterValues, cfdParameters: cfdParameterValues});
      return projectInformation;
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async duplicateScenario({ commit }, { projectId, configurationId, newScenarioName }) {
    try {
      const response = await projectApiService.duplicateScenario(projectId, configurationId, newScenarioName);
      return response;
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async updateScenario({ commit }, { projectId, studyId, configurationId, scenario, formValues, mlParameterValues, cfdParameterValues }) {
    try {
      commit('setScenarioSaveInProgress', true);
      const response = await projectApiService.replaceScenario(projectId, studyId, configurationId, scenario);
      commit('setScenarioCreationInformation', { scenario: formValues, mlParameters: mlParameterValues, cfdParameters: cfdParameterValues });
      commit('setUploadedScenario', response);
      commit('setScenarioSaveInProgress', false);
      return response;
    } catch (error) {
      commit('setError', error);
      commit('setScenarioSaveInProgress', false);
      throw error;
    }
  },
  async importResults({ commit }, {gltfMetaData }) {
    try {
      commit('setScenarioSaveInProgress', true);
      const response = await projectApiService.importResults( gltfMetaData);
      commit('setUploadedScenario', response);
      commit('setScenarioSaveInProgress', false);
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async getScenario({ commit }, { projectId, studyId, configurationId }) {
    try {
      const response = await projectApiService.getScenario(projectId, studyId, configurationId);
      commit('setScenario', response);
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async deleteScenario({ commit }, { projectId, studyId, configurationId }) {
    try {
      await projectApiService.deleteScenario(projectId, studyId, configurationId);
      commit('setProjects', null);
      commit('setProject', null);
      commit('setScenarioCreationInformation', { scenario: null, projectInformation: null, response: null, mlParameters: null });
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async hideScenario({ commit }, { projectId, studyId, configurationId }) {
    try {
      await projectApiService.hideScenario(projectId, studyId, configurationId);
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async submitScenario({ commit }, { projectId, studyId, configurationId, scenario }) {
    try {
      await projectApiService.updateScenario(projectId, studyId, configurationId, scenario);
      commit('setProjects', null);
      commit('setProject', null);
      commit('setScenarioCreationInformation', { scenario: null, projectInformation: null, response: null, mlParameters: null, cfdParameters: null });
      commit('setCreateScenarioSuccessModalVisible', true);
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async resubmitMlScenario({ commit }, { projectId, studyId, configurationId, simulationId}) {
    try {
      await projectApiService.resubmitMlScenario(projectId, studyId, configurationId, simulationId);
      commit('setProjects', null);
      commit('setProject', null);
      commit('setScenarioCreationInformation', { scenario: null, projectInformation: null, response: null, mlParameters: null });
      commit('setCreateScenarioSuccessModalVisible', true);
    } catch(error) {
      commit('setError', error);
    }
  },
  async removeUserAsProjectAdmin({ commit }, userId) {
    try {
      return await projectApiService.removeUserAsProjectAdmin(userId);
    } catch(error) {
      commit('setError', error);
    }
  },
  async getAdminProjectList({ commit }, userId) {
    try {
      return await projectApiService.getAdminProjectList(userId);
    } catch(error) {
      commit('setError', error);
    }
  },
  setCreateScenarioSuccessModalVisible({ commit }, value) {
    commit('setCreateScenarioSuccessModalVisible', value);
  },
  setViewerMode({commit}, value) {
    commit('setViewerMode', value);
  },
  setDataPanelOpen({commit}, value) {
    commit('setDataPanelOpen', value);
  },
  setSelectedScenarioFromProperties({commit}, value) {
    commit('setSelectedScenarioFromProperties', value);
  },
  setCurrentReport({ commit }, value) {
    commit('setCurrentReport', value);
  },
  setCurrentImage({ commit }, value) {
    commit('setCurrentImage', value);
  },
  setSettingsTabActive({ commit }, value) {
    commit('setSettingsTabActive', value);
  },
  setInsightTypeSelected({ commit }, value) {
    commit('setInsightTypeSelected', value);
  },
  setCurrentInsightResource({ commit }, value) {
    commit('setCurrentInsightResource', value);
  },
  setNewInsightTitle({ commit }, value) {
    commit('setNewInsightTitle', value);
  },
  setProductFruits({ commit }, value) {
    commit('setProductFruits', value);
  },
  setLayersPanelOpen({ commit }, value) {
    commit('setLayersPanelOpen', value);
  },
  setCurrentInsightImage({ commit }, value) {
    commit('setCurrentInsightImage', value);
  },
  setNewAnnotationTitle({ commit }, value) {
    commit('setNewAnnotationTitle', value);
  },
  setNewAnnotationDesc({ commit }, value) {
    commit('setNewAnnotationDesc', value);
  },
  setNewInsightDesc({ commit }, value) {
    commit('setNewInsightDesc', value);
  },
  setNewInsightStatus({ commit }, value) {
    commit('setNewInsightStatus', value);
  },
  setSavingSceneViewInsight({ commit }, value) {
    commit('setSavingSceneViewInsight', value);
  },
  setSelectedThemeIndex({ commit }, value) {
    commit('setSelectedThemeIndex', value);
  },
  setSavedCameraPositionId({ commit }, value) {
    commit('setSavedCameraPositionId', value);
  },
  setError({ commit }, error) {
    commit('setError', error);
  },
  reset({ commit }) {
    commit('resetState');
  },
  setSelectedSimulationType({ commit }, value) {
    commit('setSelectedSimulationType', value);
  },
  setCreateScenarioStep({ commit }, value) {
    commit('setCreateScenarioStep', value);
  },
  setFilesToFix({ commit }, value) {
    commit('setFilesToFix', value);
  },
  setSimulationCategory({ commit }, value ) {
    commit('setSimulationCategory', value);
  },
  async getProjectSurrounds({ commit }, projectId) {
    try {
      if (projectId) { //projectId could be undefined if the user navigated away from the calling page and chagned the route before this metods triggered
        let surrounds = await projectApiService.getProjectSurrounds(projectId);
        commit('setProjectSurrounds', surrounds);
      }
    } catch(error) {
      console.log(error);
    }
  },
  async generateSurroundsOSM(_, projectId) {
    try {
      await projectApiService.generateSurroundsOSM(projectId);
    } catch(error) {
      console.log(error);
    }
  },
  async getJobTypes({ commit }) {
    try {
      const jobTypes = await projectApiService.getJobTypes();
      commit('getJobTypes', jobTypes);
    } catch(error) {
      console.error(error);
    }
  },
  async getCriteria({ commit }) {
    try {
      const criteria = await projectApiService.getCriteria();
      commit('getCriteria', criteria);
    } catch(error) {
      console.error(error);
    }
  },
  setEulaMarketingOptIn({ commit }, value) {
    commit('setEulaMarketingOptIn', value);
  },
  setCreateInsightMode({ commit }, value) {
    commit('setCreateInsightMode', value);
  },
  setSceneViewCompositionMode({ commit }, value) {
    commit('setSceneViewCompositionMode', value);
  },
  setPinTypes({ commit }, value) {
    commit('setPinTypes', value);
  },
  setAnnotationsCompositionMode({ commit }, value) {
    commit('setAnnotationsCompositionMode', value);
  },
  setNewAnnotationAdded({ commit }, value) {
    commit('setNewAnnotationAdded', value);
  },
  setEditInsightMode({ commit }, value) {
    commit('setEditInsightMode', value);
  },
  setCreatingInsightPDF({ commit }, value) {
    commit('setCreatingInsightPDF', value);
  },
  setShowCreatingInsightPDFLoadingScreen({ commit }, value) {
    commit('setShowCreatingInsightPDFLoadingScreen', value);
  },
  setScenarioSubmissionInProgress({ commit }, value) {
    commit('setScenarioSubmissionInProgress', value);
  },
  async runAutomatedGeometryValidation({commit}, {scenario_id, simulation_id, ground, study, surrounds, presentation_planes, landscaping, mitigations, simulation_type}) {
    try {
      let response = await geometryValidationApiService.validateGeometry(scenario_id, simulation_id, ground, study, surrounds, presentation_planes, landscaping, mitigations, simulation_type);
      commit('setAutomatedGeometryValidationResults', response);
    } catch(e) {
      commit('setAutomatedGeometryValidationError', true);
    }
  },
  setAutomatedGeometryValidationError({ commit }, value) {
    commit('setAutomatedGeometryValidationError', value);
  },
  setAnnotationTypeToCreate({ commit }, annotationTypeObject) {
    commit('setAnnotationTypeToCreate', annotationTypeObject);
  },
  setDrawingMode({ commit }, value) {
    commit('setDrawingMode', value);
  },
  async saveDrawing({ commit }, drawing) {
    try {
      let drawing_id = await projectApiService.saveDrawing(drawing);
      drawing['id'] = drawing_id;
      commit('setDrawing', drawing);
    } catch(error) {
      console.error(error);
    }
  },
  async updateDrawing({ commit }, drawing) {
    try {
      await projectApiService.updateDrawing(drawing);
      commit('updateDrawingJSON', drawing);
    } catch(error) {
      console.error(error);
    }
  },
  async deleteDrawing({ commit }, drawing_id) {
    try {
      await projectApiService.deleteDrawing(drawing_id);
      commit('deleteDrawing');
    } catch(error) {
      console.error(error);
    }
  },
  setCreateInsightStep({ commit }, value) {
    commit('setCreateInsightStep', value);
  },
  async addImageView({ commit }, { projectId, studyId, insightId, insightDetails }) {
    try {
      const response = await projectApiService.addImageView(projectId, studyId, insightId, insightDetails);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async updateImageView({ commit }, { projectId, studyId, insightId, imageViewId, insightDetails }) {
    try {
      const response = await projectApiService.updateImageView(projectId, studyId, insightId, imageViewId, insightDetails);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async addStudyImage({ commit }, { projectId, studyId, studyImageDetails }) {
    try {
      const response = await projectApiService.addStudyImage(projectId, studyId, studyImageDetails);
      let savedStudyImage = {
        studyId: studyId,
        studyImage: response
      };
      commit('addImageToStudyImages', savedStudyImage);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async addReportView({ commit }, { projectId, studyId, insightId, insightDetails }) {
    try {
      const response = await projectApiService.addReportView(projectId, studyId, insightId, insightDetails);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async updateReportView({ commit }, { projectId, studyId, insightId, reportViewId, insightDetails }) {
    try {
      const response = await projectApiService.updateReportView(projectId, studyId, insightId, reportViewId, insightDetails);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async exportPDF({ commit }, { pdfDetails }) {
    try {
      const response = await projectApiService.exportPDF(pdfDetails);
      // Create a Blob from the PDF Stream
      const file = new Blob([response], { type: 'application/pdf' });

      // Create a link to download the file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', 'downloaded.pdf'); // or any other filename
      document.body.appendChild(link);
      
      link.click();
      document.body.removeChild(link); // Clean up
    } catch (error) {
      commit('setError', error);
    }
  },
  async addReport({ commit }, { projectId, studyId, reportDetails }) {
    try {
      const response = await projectApiService.addReport(projectId, studyId, reportDetails);
      let savedReport = {
        studyId: studyId,
        report: response
      };
      commit('addReportToReports', savedReport);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  }
};

const mutations = {
  deleteInsight( state, insightId) {
    let selected_study = state.selectedProject.studies.find(study => study.id === state.selectedStudyId);
    let insightIndex = selected_study.insights.findIndex(insight => insight.id == insightId);
    selected_study.insights.splice(insightIndex, 1);
  },
  addInsightToStudy(state, data) {
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    study.insights.push(data.insight);
  },
  addSceneViewToInsight(state, data) {
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    let insight = study.insights.find(insight => insight.id === data.insightId);
    insight.sceneview_set.push(data.sceneView);
  },
  addImageToStudyImages(state, data){
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    study.study_images.push(data.studyImage);
  },
  addReportToReports(state, data){
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    study.reports.push(data.report);
  },
  clearSceneViewFromInsight(state, data) {
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    let insight = study.insights.find(insight => insight.id === data.insightId);
    insight.sceneview_set = [];
  },
  addPinToInsight(state, data) {
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    let insight = study.insights.find(insight => insight.id === data.insightId);
    insight.pins.push(data.pin);
  },
  addPinTypeToInsight(state, data) {
    state.insightPinType = data.pinType;
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    let insight = study.insights.find(insight => insight.id === data.insightId);
    insight.pin_types.push(data.pinType);
  },
  deletePinFromInsight(state, data) {
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    let insight = study.insights.find(insight => insight.id === data.insightId);
    let annotation_index = insight.pins.findIndex(x => x.id === data.annotationId);

    insight.pins.splice(annotation_index, 1);
  },
  clearPinsWithPinTypeId(state, data) {
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    let insight = study.insights.find(insight => insight.id === data.insightId);
    insight.pins = insight.pins.filter(pin => pin.insight_pin_type_id !== data.annotationTypeId);
  },
  deletePinTypeFromInsight(state, data) {
    let study = state.selectedProject.studies.find(study => study.id === data.studyId);
    let insight = study.insights.find(insight => insight.id === data.insightId);
    let annotation_type_index = insight.pin_types.findIndex(x => x.id === data.annotationTypeId);

    insight.pin_types.splice(annotation_type_index, 1);
  },
  setProject(state, data) {
    if (data?.studies?.length > 0) {
      state.selectedStudyId = data.studies[0].id;
    } else {
      state.selectedStudyId = null;
    }
    
    state.selectedProject = data;
  },
  setSelectedSimulationLabels(state, data) {
    state.selectedSimulationLabels = data;
  },
  setProjectShares(state, data) {
    state.projectShares = data;
  },
  setUploadedScenario(state, data) {
    state.uploadedScenario = data;
  },
  setProjectAdministrators(state, data) {
    state.projectAdministrators = data;
  },
  setFetchMetForProject(state, id) {
    if (state.allProjects.length == 0) {
    //loading the project properties page directly and adding MET from there, allProjects is not loaded but selectdProject is
      if (state.selectedProject?.id == id) {
        state.selectedProject.fetch_met = true;
      }
    } else {
      let i = state.allProjects.findIndex(project => project.id === id);
      state.allProjects[i].fetch_met = true;
    }
  },
  setProjects(state, data) {
    state.allProjects = data;
    state.loading = data == null;
  },
  incrementProjectPage(state) {
    state.projectPage = state.projectPage + 1;
  },
  incrementprojectSearchPage(state) {
    state.projectSearchPage = state.projectSearchPage + 1;
  },
  concatProjects(state, data) {
    let allProjectIds = state.allProjects.map(proj => proj.id);
    let filteredData = data.filter(proj => !allProjectIds.includes(proj.id));
    state.allProjects = state.allProjects.concat(filteredData);
    state.loading = data == null;
  },
  setCameraPositions(state, data) {
    state.cameraPositions = data;
  },
  setSelectedStudyId(state, id) {
    state.selectedStudyId = id;
  },
  setSwitchInsight(state, insight) {
    state.currentInsightIndex = insight;
  },
  setInboundScenario(state, data) {
    state.inboundScenario = data;
  },
  setPreviewMode(state, data) {
    state.isPreviewModeOpen = data;
  },
  setCreateScenarioBtnClicked(state, data) {
    state.createScenarioBtnClicked = data;
  },
  setBackBtnClicked(state, data) {
    state.backBtnClicked = data;
  },
  setformErrors(state, data) {
    if (data.component == 'FileUploader') {
      state.fileUploadErrors = data.hasErrors;
    } else if (data.component == 'SimulationParameters') {
      state.simulationParametersErrors = data.hasErrors;
    }

  },
  setInvalidTimes(state, data) {
    state.invalidTimes = data;
  },
  setInvalidSeasons(state, data) {
    state.invalidSeasons = data;
  },
  setInvalidWinds(state, data) {
    state.invalidWinds = data;
  },
  setInvalidReturnPeriods(state, data) {
    let new_obj = _.cloneDeep(state.invalidReturnPeriods);
    new_obj[data.criteria] = data.is_invalid;
    state.invalidReturnPeriods = new_obj;
  },
  setInvalidSingleDate(state, data) {
    state.invalidSingleDate = data;
  },
  setInvalidSingleDayHours(state, data) {
    state.invalidSingleDayHours = data;
  }, 
  setInvalidMinutesInterval(state, data) {
    state.invalidMinutesInterval = data;
  },
  setFormUploading(state, data) {
    state.formUploading = data;
  },
  setInvalidFilename(state, data) {
    state.invalidFilename = data;
  },
  setError(state, error) {
    state.error = error;
  },
  setSubmittedFormValues(state, data) {
    state.submittedFormValues = data;
  },
  setProjectAssetUploadDataForProjectAssets(state, data){
    state.projectAssetUploadDataForProjectAssets = data;
  },
  setProjectAssetUploadData(state, data) {
    state.projectAssetUploadData = data;
  },
  setBadStations(state, data) {
    state.badStations = data;
  },
  setInsightPinTypeTemplates(state, data) {
    state.insightPinTypeTemplates = data;
  },
  setInsightPinType(state, data) {
    state.insightPinType = data;
  },
  setScenario(state, data) {
    state.scenario = data;
  },
  setScenarioModalVisibility(state, data) {
    state.createScenarioModalIsVisible = data;
  },
  setScenarioConfirmationModalVisibility(state, data) {
    state.scenarioConfirmationModalIsVisible = data;
  },
  setDeleteScenarioModalVisibility(state, data) {
    state.deleteScenarioModalIsVisible = data;
  },
  setScenarioCreationInformation(state, data) {
    if (data.scenario !== undefined) {
      state.scenarioCreationInformation.formValues = data.scenario;
    }

    if (data.mlParameters !== undefined) {
      state.scenarioCreationInformation.mlParameters = data.mlParameters;
    }

    if (data.projectInformation !== undefined) {
      state.scenarioCreationInformation.projectInformation = data.projectInformation;
    }

    if (data.response !== undefined) {
      state.scenarioCreationInformation.response = data.response;
    }
  },
  setCreateScenarioSuccessModalVisible(state, value) {
    state.createScenarioSuccessModalVisible = value;
  },
  setViewerMode(state, value) {
    state.viewerMode = value;
  },
  setSelectedScenarioFromProperties(state, value) {
    state.selectedScenarioFromProperties = value;
  },
  setCurrentReport(state, value) {
    state.currentReport = value;
  },
  setCurrentImage(state, value) {
    state.currentImage = value;
  },
  setSettingsTabActive(state, value) {
    state.settingsTabActive = value;
  },
  setInsightTypeSelected(state, value) {
    state.insightTypeSelected = value;
  },
  setCurrentInsightResource(state, value) {
    state.currentInsightResource = value;
  },
  setNewInsightTitle(state, value) {
    state.newInsightTitle = value;
  },
  setProductFruits(state, value) {
    state.productFruits = value;
  },
  setLayersPanelOpen(state, value) {
    state.layersPanelOpen = value;
  },
  setCurrentInsightImage(state, value) {
    state.currentInsightImage = value;
  },
  setNewAnnotationTitle(state, value) {
    state.newAnnotationTitle = value;
  },
  setNewAnnotationDesc(state, value) {
    state.newAnnotationDesc = value;
  },
  setNewInsightDesc(state, value) {
    state.newInsightDesc = value;
  },
  setNewInsightStatus(state, value) {
    state.newInsightStatus = value;
  },
  setSavingSceneViewInsight(state, value) {
    state.savingSceneViewInsight = value;
  },
  setSelectedThemeIndex(state, value) {
    state.selectedThemeIndex = value;
  },
  setSavedCameraPositionId(state, value) {
    state.setSavedCameraPositionId = value;
  },
  resetState(state) {
    Object.entries(initialState()).forEach(element => state[element[0]] = element[1]);
  },
  setSelectedSimulationType(state, value) {
    state.selectedSimulationType = value;
  },
  setCreateScenarioStep(state, value) {
    state.createScenarioStep = value;
  },
  setFilesToFix(state, value) {
    state.filesToFix = value;
  },
  setEulaMarketingOptIn(state, value) {
    state.eulaMarketingOptIn = value;
  },
  setCreateInsightMode(state, value) {
    state.createInsightMode = value;
  },
  setSceneViewCompositionMode(state, value) {
    state.sceneViewCompositionMode = value;
  },
  setPinTypes(state, value) {
    state.pinTypes = value;
  },
  setAnnotationsCompositionMode(state, value) {
    state.annotationsCompositionMode = value;
  },
  setNewAnnotationAdded(state, value) {
    state.newAnnotationAdded = value;
  },
  setEditInsightMode(state, value) {
    if(value) state.drawingMode = false;
    state.editInsightMode = value;
  },
  setCreatingInsightPDF(state, value) {
    state.creatingInsightPDF = value;
  },
  setShowCreatingInsightPDFLoadingScreen(state, value) {
    state.showCreatingInsightPDFLoadingScreen = value;
  },
  setSimulationCategory(state, data) {
    let matchingProject = state.allProjects.find(x => x.id === data.projectId);
    if (matchingProject) {
      let matchingSimulation = matchingProject.studies[0].simulation_labels.find(x => x.id == data.simulationId);
      if (matchingSimulation) {
        matchingSimulation.category = data.newCategoryValue;
      }
    }
  },
  setProjectSurrounds(state, surrounds) {
    state.projectSurrounds = surrounds;
  },
  getJobTypes(state, jobTypes) {
    state.jobTypes = jobTypes;
  },
  getCriteria(state, criteria) {
    state.criteria = criteria;
  },
  setScenarioSubmissionInProgress(state, value) {
    state.scenarioSubmissionInProgress = value;
  },
  setAutomatedGeometryValidationResults(state, response) {
    state.automatedGeometryValidationResults = response;
  },
  setAutomatedGeometryValidationError(state, error) {
    state.automatedGeometryValidationError = error;
  },
  setScenarioSaveInProgress(state, value) {
    state.scenarioSaveInProgress = value;
  },
  addInsight(state, newInsight) {
    let selected_study = state.selectedProject.studies.find(study => study.id === state.selectedStudyId);
    selected_study.insights.push(newInsight);
  },
  setAnnotationTypeToCreate(state, annotationTypeObject) {
    state.annotationTypeToCreate = annotationTypeObject;
  },
  setDrawingMode(state, value) {
    if(value) state.editInsightMode = false;
    state.drawingMode = value;
  },
  setDrawing(state, drawing) {
    let selectedStudy = state.selectedProject.studies.find(study => study.id == state.selectedStudyId);
    let insights = selectedStudy.insights.sort((first, second) => first.sort_order > second.sort_order ? 1 : -1);
    if(insights[state.currentInsightIndex].sceneview_set.length > 0) {
      insights[state.currentInsightIndex].sceneview_set[0].drawings.push(drawing);
    } else if(insights[state.currentInsightIndex].imageview_set.length > 0) {
      insights[state.currentInsightIndex].imageview_set[0].drawings.push(drawing);
    }
  },
  updateDrawingJSON(state, drawing) {
    let selectedStudy = state.selectedProject.studies.find(study => study.id == state.selectedStudyId);
    let insights = selectedStudy.insights.sort((first, second) => first.sort_order > second.sort_order ? 1 : -1);
    if(insights[state.currentInsightIndex].sceneview_set.length > 0) {
      insights[state.currentInsightIndex].sceneview_set[0].drawings[0].drawing_json = drawing.drawing_json;
      insights[state.currentInsightIndex].sceneview_set[0].drawings[0].name = drawing.name;
      insights[state.currentInsightIndex].sceneview_set[0].drawings[0].horizontal_translation_factor = drawing.horizontal_translation_factor;
    } else if(insights[state.currentInsightIndex].imageview_set.length > 0) {
      insights[state.currentInsightIndex].imageview_set[0].drawings[0].drawing_json = drawing.drawing_json;
      insights[state.currentInsightIndex].imageview_set[0].drawings[0].name = drawing.name;
      insights[state.currentInsightIndex].imageview_set[0].drawings[0].horizontal_translation_factor = drawing.horizontal_translation_factor;
    }
  },
  deleteDrawing(state) {
    let selectedStudy = state.selectedProject.studies.find(study => study.id == state.selectedStudyId);
    let insights = selectedStudy.insights.sort((first, second) => first.sort_order > second.sort_order ? 1 : -1);
    if(insights[state.currentInsightIndex].sceneview_set.length > 0) {
      insights[state.currentInsightIndex].sceneview_set[0].drawings = [];
    } else if(insights[state.currentInsightIndex].imageview_set.length > 0) {
      insights[state.currentInsightIndex].imageview_set[0].drawings = [];
    }
  },
  setCreateInsightStep(state, value) {
    state.createInsightStep = value;
  },
  setDataPanelOpen(state, value) {
    state.dataPanelOpen = value; 
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    viewer,
    simulationAsset
  }
};